import React from 'react';
import styles from './styles.module.scss';

// ** Mui-Material Imports
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


const FormRadio = (prop) => {

  const {
    // title,
    value,
    onChange,
  } = prop;


  return (
    <FormControl>
      <RadioGroup
        row
        // defaultValue="cm"
        className={styles.radio}
        value={value}
        onChange={onChange}
      >
        <FormControlLabel value="inch" control={<Radio />} label="inch" />
        <FormControlLabel value="cm" control={<Radio />} label="cm" />
      </RadioGroup>
    </FormControl>
  );
};

export default FormRadio;
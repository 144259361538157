import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './styles.module.scss';
import path from '../../utils/path';
import FormData from 'form-data';

// ** Component Imports
import Aside from '../../components/Aside';
import UploadFile from '../../components/UploadFile';

// ** Icon Imports
import { AlertCircle, Upload, Delete, DeleteEmpty } from 'mdi-material-ui';

//** Store Import
import { setHomePageReset, uploadImage } from '../../store/action';
import { StoreContext } from '../../store/reducer';

const Home = () => {

  const history = useHistory();
  const [uploadFile, setUploadFile] = useState([]);
  const [hover, setHover] = useState(false);

  const {
    dispatch
  } = useContext(StoreContext);

  useEffect(() => {
    setHomePageReset(dispatch);
  }, [])

  const handleFileChange = (upload) => {
    setUploadFile(upload);
  }

  const removeFile = (id) => {
    setHover(false);
    setUploadFile(uploadFile.filter((x) => x.id !== id));
  }

  const handleUpload = () => {
    let formData = new FormData();
    formData.append('file', uploadFile[0]['file']);
    uploadImage(dispatch, { formData: formData })
    history.push(path.deepSearch)
  }

  function handleFileSize(size) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return size.toFixed(2) + ' ' + units[i];
  }

  let hoverTimeout;

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      setHover(true);
    }, 10);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      setHover(false);
    }, 10);
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>首頁</title>
        <meta name="description" content="首頁" />
      </Helmet>
      <div className={styles.screen}>
        <Aside
          isHomePage={true}
        ></Aside>
        <div className={styles.screenContent}>
          <div className={styles.uploadTitleContent}>
            <div className={styles.uploadTitle}>
              <div className={styles.uploadTitle_title}>Upload File</div>
              <div className={styles.uploadTitle_underLine}></div>
            </div>
            <div className={styles.uploadInfo}>
              <AlertCircle className={styles.uploadInfo_infoIcon} />
              <div className={styles.uploadInfo_text}>After uploading the image file, the system will generate similar styles.</div>
            </div>
          </div>
          <div className={styles.uploadFileContent}>
            <UploadFile
              fileValue={uploadFile}
              maxFiles={1}
              behaviour={'replace'}
              onChangeHandle={(event) => handleFileChange(event)}
              onDeleteHandle={(id) => removeFile(id)}
              className={styles.dropzone}
            ></UploadFile>
            {uploadFile.length !== 0 ?
              <div className={styles.uploadFile_showContent}>
                <div className={styles.showContent_left}>
                  <div className={styles.imgContent}>
                    <img src={URL.createObjectURL(uploadFile[0]['file'])} alt='uploadImage'></img>
                  </div>
                  <div className={styles.imgTitle}>{uploadFile[0]['name']}</div>
                </div>
                <div className={styles.showContent_right}>
                  <div className={styles.fileSize}>{handleFileSize(uploadFile[0]['size'])}</div>
                  <div className={styles.line}>|</div>
                  <div
                    className={styles.iconContent}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => removeFile(uploadFile[0]['id'])}
                  >
                    {hover ? <DeleteEmpty /> : <Delete />}
                  </div>
                </div>
              </div>
              : null
            }
            <button className={styles.uploadContent_btn} onClick={handleUpload}>
              <div>UPLOAD</div>
              <Upload className={styles.uploadContent_icon}></Upload>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
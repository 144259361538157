import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import path from '../../utils/path';
import styles from './styles.module.scss';

// ** Mui-Material Imports
import { Avatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// ** Assets Imports
import Logo from '../../assets/image/logo.png';

// ** Icons Imports
import { BellOutline, BellRingOutline, Home, HomeImportOutline } from 'mdi-material-ui';


const Header = () => {

  const [homeHover, setHomeHover] = useState(false);
  const [bellHover, setBellHover] = useState(false);

  let hoverTimeout;

  const handleMouseEnter = (type) => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      if (type === 'home') setHomeHover(true);
      if (type === 'bell') setBellHover(true);
    }, 10);
  };

  const handleMouseLeave = (type) => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      if (type === 'home') setHomeHover(false);
      if (type === 'bell') setBellHover(false);
    }, 10);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <div className={styles.container}>
      {/* <header className={styles.header}>
        <Link to={path.home} className={styles.header_leftSide}>
          <div className={styles.logoSide}>
            <img src={Logo} className={styles.logo} alt="logo"></img>
          </div>
          <div className={styles.logoName}>MAKALOT</div>
          <IconButton
            onMouseEnter={() => handleMouseEnter('home')}
            onMouseLeave={() => handleMouseLeave('home')}
          >
            {homeHover ?
              <HomeImportOutline className={styles.homeIcon} />
              :
              <Home className={styles.homeIcon} />
            }
          </IconButton>
        </Link>
        <div className={styles.header_rightSide}>
          <IconButton
            onMouseEnter={() => handleMouseEnter('bell')}
            onMouseLeave={() => handleMouseLeave('bell')}
          >
            {bellHover ?
              <BellRingOutline className={styles.bellIcon} />
              :
              <BellOutline className={styles.bellIcon} />
            }
          </IconButton>
          <Avatar {...stringAvatar('Jennifer Guo')} />
        </div>
      </header> */}
    </div>
  );
};

export default Header;
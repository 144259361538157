import React from 'react';

// ** Mui-Material Imports
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    }
  }
};

const FormSelectMulti = (prop) => {

  const {
    value,
    onChange,
    selectionsMap = [],
    disabled = false,
  } = prop;

  return (
    <FormControl sx={{ m: 1, width: '100%', ml: '0px', background: '#fff' }}>
      <InputLabel id="demo-multiple-checkbox-label">ProductItems</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label="ProductItems" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
        disabled={disabled}
      >
        {selectionsMap.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={value.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormSelectMulti;
import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';

// ** Mui-Material Imports
import { Collapse, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

// ** Component Imports
import ListTitle from '../ListItemButton';
import FormSelect from '../FormSelect';
import FormRadio from '../FormRadio';
import FormNum from '../FormNum';
import FormSelectMulti from '../FormSelectMulti';
import { Link } from 'react-router-dom';
import path from '../../utils/path';
import Logo from '../../assets/image/logo.png';
import { BellOutline, BellRingOutline, Home, HomeImportOutline } from 'mdi-material-ui';

// ** Icon Imports
import { GamepadCircle, Refresh, FilterMultiple } from 'mdi-material-ui';

// ** JSON Imports
import filterState from '../../assets/json/filterState.json';

// ** Store Imports
import { StoreContext } from '../../store/reducer';
import { setShowDetail, setFilterImage, setDetailUnit, setFilterImageByTB, setFilterReset } from '../../store/action';

// color
const ColorButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#8B78B7',
  borderRadius: '10px',
  marginTop: '5px',
  '&:hover': {
    backgroundColor: '#7056AB',
  },
}));

const Aside = (prop) => {

  const { isHomePage = false } = prop;

  const [openKeyWords, setOpenKeyWords] = useState(false);
  const [keyWords, setKeyWords] = useState('');
  const [filterFlag, setFilterFlag] = useState(false);
  const [filterSelections, setFilterSelections] = useState({});
  const [canFilterItems, setCanFilterItems] = useState(true);

  const [openSections, setOpenSections] = useState({
    ProductItems: true,
    Gender: false,
    Customer: false,
    Season: false,
    Year: false,
  });

  const [sectionValues, setSectionValues] = useState({
    ProductItems: [],
    Gender: 'Default',
    Customer: 'Default',
    Season: 'Default',
    Year: 'Default',
  });

  const [openDetailSections, setOpenDetailSections] = useState({
    Unit: false,
    Top: false,
    Bottom: false,
  });

  const [topValues, setTopValues] = useState({
    Shoulder: '',
    Chest: '',
    Sweep: '',
    'Body L.': '',
    'SLV L.': '',
    // 'No of Tops': '',
  })

  const [bottomValues, setBottomValues] = useState({
    Waist: '',
    'F Rise': '',
    'B Rise': '',
    Length: '',
    Inseam: '',
    'Leg W.': '',
  })

  const [unit, setUnit] = useState('inch');

  const [showDetailText, setShowDetailText] = useState('Show Style Detail');
  const [isShowDetail, setIsShowDetail] = useState(false);
  
  const [homeHover, setHomeHover] = useState(false);
  const [bellHover, setBellHover] = useState(false);

  let hoverTimeout;

  const handleMouseEnter = (type) => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      if (type === 'home') setHomeHover(true);
      if (type === 'bell') setBellHover(true);
    }, 10);
  };

  const handleMouseLeave = (type) => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      if (type === 'home') setHomeHover(false);
      if (type === 'bell') setBellHover(false);
    }, 10);
  };


  const {
    state: {
      searchSimilar: { isSearchSimilar, targetSearch },
      selections,
      filterSelected,
      requestdata: { loading },
    },
    dispatch,
  } = useContext(StoreContext);

  useEffect(() => {
    if (loading === false && isHomePage === false) {
      const localSelections = JSON.parse(localStorage.getItem('localSelections'));
      const localFilterSelected = JSON.parse(localStorage.getItem('localFilterSelected'));

      if (Object.keys(selections).length === 0) {
        if (localSelections) {
          setFilterSelections(JSON.parse(JSON.stringify(localSelections)));
        }
      } else {
        setFilterSelections(JSON.parse(JSON.stringify(selections)));
      }

      if (localFilterSelected) {
        setSectionValues(JSON.parse(JSON.stringify(localFilterSelected)));
      }
    }
  }, [loading, selections, isHomePage, filterSelected])


  // 其他選項filter時，ProductItems更動
  // useEffect(() => {
  //   if (loading === false && Object.keys(filterSelections).length !== 0) {

  //     const filteredProductItems = sectionValues.ProductItems.filter((item) => {
  //       return filterSelections.ProductItems.includes(item);
  //     })

  //     setSectionValues(prevValues => ({
  //       ...prevValues,
  //       ProductItems: filteredProductItems,
  //     }));

  //   }
  // }, [loading, filterSelections])

  const handleUnitChange = (num) => {
    if (unit === 'cm') {
      let cm = (num * 2.54).toFixed(2);
      return cm;
    } else if (unit === 'inch') {
      let inch = (num * 0.3937).toFixed(2);
      return inch;
    }
  };

  useEffect(() => {
    setDetailUnit(dispatch, { unit: unit });

    const updatedTopValues = { ...topValues };
    for (const key in updatedTopValues) {
      if (updatedTopValues[key] !== '') {
        updatedTopValues[key] = handleUnitChange(updatedTopValues[key], unit);
      }
    }

    const updatedBottomValues = { ...bottomValues };
    for (const key in updatedBottomValues) {
      if (updatedBottomValues[key] !== '') {
        updatedBottomValues[key] = handleUnitChange(updatedBottomValues[key], unit);
      }
    }

    setTopValues(updatedTopValues);
    setBottomValues(updatedBottomValues);
  }, [unit])

  useEffect(() => {
    if (filterFlag === true && loading === false) {
      setFilterImage(dispatch, { selections: sectionValues })
      setFilterFlag(false);
    }
  }, [filterFlag, loading])

  useEffect(() => {
    setShowDetail(dispatch, isShowDetail);
  }, [isShowDetail])

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const toggleDetailSection = (section) => {
    setOpenDetailSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  // Show Style Detail Btn Click
  const handleDetailTitle = () => {
    setIsShowDetail(!isShowDetail);
    setShowDetailText((prevText) =>
      prevText === 'Show Style Detail' ? 'Close Style Detail' : 'Show Style Detail'
    );
  }

  // Filters Change
  const handleFilterChange = (e, section) => {
    setSectionValues((prevValues) => ({
      ...prevValues,
      [section]: e.target.value,
    }));
    setFilterFlag(true);
  }

  // Top or Bottom Change
  const handleDetailValueChange = (title, newValue, top) => {
    if (top === true) {
      setTopValues((prevValues) => ({
        ...prevValues,
        [title]: newValue,
      }));
    } else if (top === false) {
      setBottomValues((prevValues) => ({
        ...prevValues,
        [title]: newValue,
      }));
    }
  };

  const handleShowResultsBtn = () => {
    let obj = {};
    Object.entries(topValues).forEach(([key, value]) => {
      if (value !== '') {
        obj[key] = unit === 'inch' ? value : (value * 0.3937).toFixed(2);
      }
    })
    Object.entries(bottomValues).forEach(([key, value]) => {
      if (value !== '') {
        obj[key] = unit === 'inch' ? value : (value * 0.3937).toFixed(2);
      }
    })

    setFilterImageByTB(dispatch, { topBottomObj: obj })
  }

  const handleFilterReset = () => {
    setFilterReset(dispatch, { sectionValues: filterState.sectionValues });
    setOpenSections(filterState.openSections);
    setSectionValues(filterState.sectionValues);
    setOpenDetailSections(filterState.openDetailSections);
    setTopValues(filterState.topValues);
    setBottomValues(filterState.bottomValues);
    setCanFilterItems(true);
  }

  const handleItemsChange = (e) => {
    const { target: { value } } = e;
    setSectionValues((prevValues) => ({
      ...prevValues,
      ProductItems: typeof value === "string" ? value.split(",") : value,
    }));
  }

  const handleItemsFilter = () => {
    setFilterImage(dispatch, { selections: sectionValues })
    setCanFilterItems(false);
  }

  return (
    <div className={styles.container}>
      <aside className={styles.aside}>
        {isHomePage ? <></>
            :
        <Link to={path.home} className={styles.header_leftSide}>
          <IconButton
            onMouseEnter={() => handleMouseEnter('home')}
            onMouseLeave={() => handleMouseLeave('home')}
          >
            {homeHover ?
              <HomeImportOutline className={styles.homeIcon} />
              :
              <Home className={styles.homeIcon} />
            }
          </IconButton>
        </Link>}
        {(isSearchSimilar && isHomePage === false) ?
          <div className={styles.searchSimilarContent}>
            <div className={styles.aside_title}>ORIGINAL PHOTO</div>
            <div className={styles.imgContent}>
              <img src={targetSearch.src}></img>
            </div>
          </div>
          : <></>
        }
        <div className={styles.filter}>
          <div className={styles.aside_title}>
            <div className={styles.titleContent}>
              <GamepadCircle className={styles.aside_titleIcon} />
              <div>FILTERS</div>
            </div>
            {!isHomePage &&
              <Tooltip title="Reset Filters" arrow placement="top">
                <IconButton onClick={handleFilterReset}>
                  <Refresh ></Refresh>
                </IconButton>
              </Tooltip>
            }
          </div>
          <div className={styles.list}>
            <ListTitle
              title={'Key Words'}
              openValue={openKeyWords}
              handleClick={() => setOpenKeyWords(!openKeyWords)}
              className={styles.listTitleContent}
            ></ListTitle>
            <Collapse in={openKeyWords} timeout="auto" unmountOnExit className={styles.listCollapse}>
              <input
                className={styles.input_box}
                type="text"
                onChange={(e) => {
                  setKeyWords(e.target.value);
                }}
              />
            </Collapse>
          </div>
          {isHomePage ? <></>
            :
            <>
              {Object.entries(openSections).map(([section, isOpen]) => (
                <div className={styles.list} key={section}>
                  <ListTitle
                    title={section}
                    openValue={isOpen}
                    handleClick={() => toggleSection(section)}
                    className={styles.listTitleContent}
                  />
                  <Collapse in={isOpen} timeout="auto" unmountOnExit className={styles.listCollapse}>
                    {loading ? (<></>) : (
                      section === 'ProductItems' ? (
                        <div className={styles.productItemsContent}>
                          <FormSelectMulti
                            value={sectionValues[section]}
                            selectionsMap={filterSelections[section]}
                            onChange={(e) => handleItemsChange(e)}
                            disabled={!canFilterItems}
                          />
                          <ColorButton
                            variant="contained"
                            endIcon={<FilterMultiple />}
                            onClick={handleItemsFilter}
                            disabled={!canFilterItems}
                          >Filter Items</ColorButton>
                        </div>
                      ) : (
                        <FormSelect
                          value={sectionValues[section]}
                          selectionsMap={filterSelections[section]}
                          onChange={(e) => handleFilterChange(e, section)}
                        />
                      )
                    )}
                  </Collapse>
                </div>
              ))}
              {/* <button className={`${styles.btn} ${styles.btn_showDetail}`} onClick={handleDetailTitle}>
                <div>{showDetailText}</div>
              </button> */}
              {isShowDetail && (<>
                {Object.entries(openDetailSections).map(([section, isOpen]) => (
                  <div className={styles.list} key={section}>
                    <ListTitle
                      title={section}
                      openValue={isOpen}
                      handleClick={() => toggleDetailSection(section)}
                      className={styles.listTitleContent}
                    />
                    <Collapse in={isOpen} timeout="auto" unmountOnExit className={styles.listCollapse}>
                      {section === 'Unit' ?
                        <FormRadio
                          value={unit}
                          onChange={(e) => { setUnit(e.target.value) }}
                        >
                        </FormRadio>
                        : null
                      }
                      {section === 'Top' ?
                        <>
                          {Object.entries(topValues).map(([title, value]) => (
                            <FormNum
                              key={title}
                              title={title}
                              value={value}
                              top={true}
                              max={100}
                              min={0}
                              onChange={handleDetailValueChange}
                            ></FormNum>
                          ))}
                        </>
                        : null
                      }
                      {section === 'Bottom' ?
                        <>
                          {Object.entries(bottomValues).map(([title, value]) => (
                            <FormNum
                              key={title}
                              title={title}
                              value={value}
                              top={false}
                              max={100}
                              min={0}
                              onChange={handleDetailValueChange}
                            ></FormNum>
                          ))}
                        </>
                        : null
                      }
                    </Collapse>
                  </div>
                ))}
                <button className={styles.btn} onClick={handleShowResultsBtn}>
                  <div>Show Results</div>
                </button>
              </>
              )}
            </>}

        </div>
        {/* About Records */}
        {/* {
          isHomePage ?
            <div className={styles.aside_title}>
              <div className={styles.titleContent}>
                <GamepadCircle className={styles.aside_titleIcon} />
                <div>RECORDS</div>
              </div>
            </div>
            : null
        } */}
      </aside >
    </div >
  );
};

export default Aside;
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { StoreProvider } from "../store/reducer";
import path from "../utils/path";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HomePage from "../layouts/Home";
import DeepSearchPage from "../layouts/DeepSearch";
// import Aside from "../components/Aside";

const Routes = () => {
  return (
    <StoreProvider>
      <Router>
        <Header />
        {/* <Aside /> */}
        <Switch>
          {/* 首頁 */}
          <Route exact path={path.home} component={HomePage} />
          {/* 以圖搜圖 */}
          <Route exact path={path.deepSearch} component={DeepSearchPage} />
        </Switch>
        <Footer />
      </Router>
    </StoreProvider>
  );
};

export default Routes;
import styles from './styles.module.scss';

const Footer = () => {
  return (
    <div className={styles.container}>
      {/* <footer className={styles.footer}>
        copyright © 2023 MAKALOT industrial co., Itd., all rights reserved.
      </footer> */}
    </div>
  );
};

export default Footer;
import React from 'react';

// ** Mui-Material Imports
import { ListItemButton } from '@mui/material';

// ** Icon Imports
import { ChevronUp, ChevronDown } from 'mdi-material-ui';

const ListTitle = (prop) => {

  const {
    title,
    openValue,
    handleClick,
    className
  } = prop;


  return (

    <ListItemButton onClick={handleClick} className={className}>
      <div>{title}</div>
      {openValue ? <ChevronUp /> : <ChevronDown />}
    </ListItemButton>

  );
};

export default ListTitle;
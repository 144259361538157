import axios from "axios";
import {
    SET_HOMEPAGE_RESET,
    SET_SHOW_DETAIL,
    SET_SEARCH_SIMILAR,
    SET_UPLOAD_RESULT,
    SET_DETAIL_UNIT,

    // 取得資料狀態
    BEGIN_DATA_REQUEST,
    SUCCESS_DATA_REQUEST,
    FAIL_DATA_REQUEST,
} from "./actionTypes";

const SERVER_URL = 'https://stylesearch.portalm.io/stylesearch';

export const setHomePageReset = (dispatch) => {
    dispatch({ type: SET_HOMEPAGE_RESET });
    localStorage.clear();
}

export const setShowDetail = (dispatch, isShowDetail) => {
    dispatch({
        type: SET_SHOW_DETAIL,
        payload: isShowDetail,
    })
}

export const setSearchSimilar = (dispatch, option) => {
    const { targetSearch } = option;
    dispatch({
        type: SET_SEARCH_SIMILAR,
        payload: targetSearch,
    })
}

const topMapping = {
    across: 'Shoulder',
    chest: 'Chest',
    sweep: 'Sweep',
    body: 'Body L.',
    sleeve: 'SLV L.',
}

const bottomMapping = {
    waist: 'Waist',
    front: 'F Rise',
    back: 'B Rise',
    length: 'Length',
    inseam: 'Inseam',
    leg: 'Leg W.',
}

const topBottomMapping = {
    'Shoulder': 'across',
    'Chest': 'chest',
    'Sweep': 'sweep',
    'Body L.': 'body',
    'SLV L.': 'sleeve',
    'Waist': 'waist',
    'F Rise': 'front',
    'B Rise': 'back',
    'Length': 'length',
    'Inseam': 'inseam',
    'Leg W.': 'leg',
}

export const uploadImage = async (dispatch, options) => {
    const { formData, searchByImage = false } = options;
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        if (searchByImage) {
            var { data } = await axios.post(
                SERVER_URL + '/api/file-search', {
                src: formData,
            }
            );
        } else {
            var { data } = await axios.post(
                SERVER_URL + '/api/image-search', formData
            );
        }

        let targetItems = data.label_cust.split(';');

        let select = {
            ProductItems: [],
            Gender: ['Default'],
            Customer: ['Default'],
            Season: ['Default'],
            Year: ['Default'],
            Top: {
                Shoulder: { max: 0, min: 0 },
                Chest: { max: 0, min: 0 },
                Sweep: { max: 0, min: 0 },
                'Body L.': { max: 0, min: 0 },
                'SLV L.': { max: 0, min: 0 },
            },
            Bottom: {
                Waist: { max: 0, min: 0 },
                'F Rise': { max: 0, min: 0 },
                'B Rise': { max: 0, min: 0 },
                Length: { max: 0, min: 0 },
                Inseam: { max: 0, min: 0 },
                'Leg W.': { max: 0, min: 0 },
            },
        }

        data.imglist.forEach((result, index) => {
            if (!select.ProductItems.includes(result.item)) select.ProductItems.push(result.item);
            if (!select.Gender.includes(result.category)) select.Gender.push(result.category);
            if (!select.Customer.includes(result.customer)) select.Customer.push(result.customer);
            if (!select.Season.includes(result.season)) select.Season.push(result.season);
            if (!select.Year.includes(result.year)) select.Year.push(result.year);
            if (result.across && result.across < select.Top.Shoulder.min) select.Top.Shoulder.min = result.across;
            if (result.across && result.across > select.Top.Shoulder.max) select.Top.Shoulder.max = result.across;

            for (const key in topMapping) {
                const selectKey = topMapping[key];
                if (index === 0) {
                    select.Top[selectKey].min = result[key];
                    select.Top[selectKey].max = result[key];
                }
                if (result[key] && result[key] < select.Top[selectKey].min) select.Top[selectKey].min = result[key];
                if (result[key] && result[key] > select.Top[selectKey].max) select.Top[selectKey].max = result[key];
            }

            for (const key in bottomMapping) {
                const selectKey = bottomMapping[key];
                if (index === 0) {
                    select.Bottom[selectKey].min = result[key];
                    select.Bottom[selectKey].max = result[key];
                }
                if (result[key] && result[key] < select.Bottom[selectKey].min) select.Bottom[selectKey].min = result[key];
                if (result[key] && result[key] > select.Bottom[selectKey].max) select.Bottom[selectKey].max = result[key];
            }
        })

        const filterResults = !targetItems[0] ? data.imglist : data.imglist.filter((obj) => {
            if (targetItems.includes(obj.item)) return true;
            return false;
        })

        let selectedItems = targetItems.filter((item) => {
            return select.ProductItems.includes(item);
        })

        let selected = {
            ProductItems: selectedItems,
            Gender: 'Default',
            Customer: 'Default',
            Season: 'Default',
            Year: 'Default',
        }

        localStorage.setItem('localOriginSelections', JSON.stringify(select));
        localStorage.setItem('localSelections', JSON.stringify(select));
        localStorage.setItem('localSearchResults', JSON.stringify(data.imglist));
        localStorage.setItem('localFilterResults', JSON.stringify(filterResults));
        localStorage.setItem('localFilterSelected', JSON.stringify(selected));

        let obj = {
            searchResults: filterResults,
            selections: select,
        }

        dispatch({
            type: SET_UPLOAD_RESULT,
            payload: obj,
        })

        dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (err) {
        console.log('err: ' + err);
        dispatch({
            type: FAIL_DATA_REQUEST,
            payload: err,
        });
    }
}

export const setFilterImage = async (dispatch, options) => {
    const { selections } = options;

    const contrastKey = {
        ProductItems: 'item',
        Gender: 'category',
        Customer: 'customer',
        Season: 'season',
        Year: 'year',
    }

    const localSearchResults = JSON.parse(localStorage.getItem('localSearchResults'));
    const localSelections = JSON.parse(localStorage.getItem('localSelections'));
    const localTopBottomObj = JSON.parse(localStorage.getItem('localTopBottomObj'));

    try {
        dispatch({ type: BEGIN_DATA_REQUEST });

        const filterResults = localSearchResults.filter((obj) => {
            for (const filterKey in selections) {
                const key = contrastKey[filterKey];
                const filterValue = selections[filterKey];

                if (key === 'item') {
                    if (filterValue.length !== 0) {
                        if (!filterValue.includes(obj[key])) return false;
                    }
                } else {
                    if (filterValue !== 'Default') {
                        if (obj[key] !== filterValue) {
                            return false;
                        }
                    }
                }
            }
            return true;
        })

        console.log(filterResults);

        if (localTopBottomObj && Object.keys(localTopBottomObj).length !== 0) {
            filterResults.forEach((result) => {
                let count = 0;
                Object.entries(localTopBottomObj).forEach(([key, value]) => {
                    value = +value;
                    if (Object.keys(result).includes(topBottomMapping[key]) && (result[topBottomMapping[key]])) {
                        count += ((result[topBottomMapping[key]] - value) ** 2);
                    } else {
                        count += (value ** 2)
                    }
                })
                result.count = count;
            })

            filterResults.sort((a, b) => {
                return a.count - b.count;
            })
        }

        let select = {
            ProductItems: [],
            Gender: ['Default'],
            Customer: ['Default'],
            Season: ['Default'],
            Year: ['Default'],
        }
        filterResults.forEach((result) => {
            if (!select.ProductItems.includes(result.item)) select.ProductItems.push(result.item);
            if (!select.Gender.includes(result.category)) select.Gender.push(result.category);
            if (!select.Customer.includes(result.customer)) select.Customer.push(result.customer);
            if (!select.Season.includes(result.season)) select.Season.push(result.season);
            if (!select.Year.includes(result.year)) select.Year.push(result.year);
        })

        select.Top = localSelections.Top;
        select.Bottom = localSelections.Bottom;

        let obj = {
            searchResults: filterResults,
            selections: select,
        }

        dispatch({
            type: SET_UPLOAD_RESULT,
            payload: obj,
        })

        localStorage.setItem('localSelections', JSON.stringify(select));
        localStorage.setItem('localFilterResults', JSON.stringify(filterResults));
        localStorage.setItem('localFilterSelected', JSON.stringify(selections));

        dispatch({ type: SUCCESS_DATA_REQUEST });

    } catch (err) {
        dispatch({
            type: FAIL_DATA_REQUEST,
            payload: err,
        });
    }
}



export const setFilterImageByTB = (dispatch, option) => {
    const { topBottomObj } = option;

    const localFilterResults = JSON.parse(localStorage.getItem('localFilterResults'));
    const localSelections = JSON.parse(localStorage.getItem('localSelections'));

    let filterResults = JSON.parse(JSON.stringify(localFilterResults));

    if (Object.keys(topBottomObj).length !== 0) {
        filterResults.forEach((result) => {
            let count = 0;
            Object.entries(topBottomObj).forEach(([key, value]) => {
                value = +value;
                if (Object.keys(result).includes(topBottomMapping[key]) && (result[topBottomMapping[key]])) {
                    count += ((result[topBottomMapping[key]] - value) ** 2);
                } else {
                    count += (value ** 2)
                }
            })
            result.count = count;
        })

        filterResults.sort((a, b) => {
            return a.count - b.count;
        })
    }

    let obj = {
        searchResults: filterResults,
        selections: localSelections,
    }

    localStorage.setItem('localTopBottomObj', JSON.stringify(topBottomObj));
    localStorage.setItem('localSelections', JSON.stringify(localSelections));
    localStorage.setItem('localFilterResults', JSON.stringify(filterResults));

    dispatch({
        type: SET_UPLOAD_RESULT,
        payload: obj,
    })
}


export const setDetailUnit = (dispatch, option) => {
    const { unit = 'cm' } = option;
    dispatch({
        type: SET_DETAIL_UNIT,
        payload: unit,
    })
}

export const setDownloadDXFTP = async (dispatch, option) => {
    const { style, fileName, fileType } = option;
    try {
        dispatch({ type: BEGIN_DATA_REQUEST });
        const data = await axios.post('https://stylesearch.portalm.io/deepsearch/download', {
            stylename: style,
            filename: fileName,
            filetype: fileType
        }, {
            responseType: 'blob',
        })

        const file = new Blob([data.data]);
        dispatch({ type: SUCCESS_DATA_REQUEST });
        return file;
    } catch (err) {
        dispatch({
            type: FAIL_DATA_REQUEST,
            payload: err
        })
    }
}

export const setDownloadGlb = async (dispatch, option) => {
    const { src = ''} = option;
    try {
        dispatch({ type: BEGIN_DATA_REQUEST });

        let obj;

        obj = {
            id: src,
        }
        const data = await axios.post('https://stylesearch.portalm.io/stylesearch/api/get-link', obj, {
            useCredentails: true, responseType: 'json'
        });
        dispatch({ type: SUCCESS_DATA_REQUEST });
        return data;
    } catch (err) {
        dispatch({
            type: FAIL_DATA_REQUEST,
            payload: err
        })
    }
}

export const setDownload3D = async (dispatch, option) => {
    const { image_f = '', image_b = '', format = 'PNG', mode } = option;
    try {
        dispatch({ type: BEGIN_DATA_REQUEST });

        let obj;

        if (mode === '3D') {
            obj = {
                image_f: image_f,
                image_b: image_b,
                format: format,
                mode: mode,
            }
        } else {
            obj = {
                image_f: image_f,
                format: format,
                mode: mode,
            }
        }

        const data = await axios.post('https://stylesearch.portalm.io/deepsearch/download-image ', obj, {
            useCredentails: true, responseType: 'arraybuffer'
        });

        const file = new Blob([data.data]);
        dispatch({ type: SUCCESS_DATA_REQUEST });
        return file;
    } catch (err) {
        dispatch({
            type: FAIL_DATA_REQUEST,
            payload: err
        })
    }
}

export const setFilterReset = (dispatch, option) => {
    const { sectionValues } = option;
    const localSearchResults = JSON.parse(localStorage.getItem('localSearchResults'));
    const localOriginSelections = JSON.parse(localStorage.getItem('localOriginSelections'));

    localStorage.setItem('localSelections', JSON.stringify(localOriginSelections));
    localStorage.setItem('localFilterResults', JSON.stringify(localSearchResults));
    localStorage.setItem('localFilterSelected', JSON.stringify(sectionValues));

    let obj = {
        searchResults: localSearchResults,
        selections: localOriginSelections,
    }

    dispatch({
        type: SET_UPLOAD_RESULT,
        payload: obj,
    })
}
import React from 'react';
import styles from './styles.module.scss';

// ** Mui-Material Imports
import IconButton from '@mui/material/IconButton';

import { AlertCircleOutline, CloseCircleOutline } from 'mdi-material-ui';

const AlertComponent = (prop) => {

  const {
    // title,
    fileType = '',
    styleNo = '',
    handleClose
  } = prop;


  return (
    <div className={styles.errorContent}>
      <div className={styles.topContent}>
        <div className={styles.titleContent}>
          <AlertCircleOutline className={styles.errorIcon}></AlertCircleOutline>
          <div className={styles.title}>Error</div>
        </div>
        <IconButton sx={{ mt: '-5px' }} onClick={handleClose}>
          <CloseCircleOutline sx={{ fontSize: '28px' }} />
        </IconButton>
      </div>
      <div className={styles.text}>
        {`Sorry, the `}
        <strong style={{ fontWeight: 'bold' }}>{fileType}</strong>
        {` file for `}
        <strong style={{ fontWeight: 'bold' }}>{styleNo}</strong>
        {` cannot be downloaded.`}
      </div>
    </div>
  );
};

export default AlertComponent;
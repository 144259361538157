import styles from './styles.module.scss';

// ** Dropzone Import
import { Dropzone, FileMosaic } from "@dropzone-ui/react";

const UploadFile = (prop) => {

  const {
    fileType,
    onChangeHandle,
    fileValue,
    maxFiles,
    behaviour,
    onDeleteHandle,
    className
  } = prop;

  return (
    <div className={styles.container}>
      <Dropzone
        onChange={onChangeHandle}
        value={fileValue}
        accept={fileType}
        maxFiles={maxFiles}
        label='Drop your file here'
        header={false}
        behaviour={behaviour}
        className={className}
      >
        {fileValue.map((file) => (
          <FileMosaic key={file.id} {...file} onDelete={onDeleteHandle} info />
        ))}
      </Dropzone>
    </div>
  );
};

export default UploadFile;
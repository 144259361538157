import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import styles from './styles.module.scss';
import { saveAs } from 'file-saver';

// ** Component Imports
import Aside from '../../components/Aside';
import Alert from '../../components/Alert';

// ** Icon Imports
import {
  TrayArrowDown,
  ImageSearchOutline,
  Link,
  EmailEditOutline,
  CloseCircleOutline,
  ArrowLeftCircleOutline,
  GamepadCircle,
  Label,
  ChevronUp,
  FileDocumentRemoveOutline,
} from 'mdi-material-ui';

// ** MUI Imports
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Fab from '@mui/material/Fab';
import { Button, IconButton } from '@mui/material';

// ** Bootstrap Imports
import Form from 'react-bootstrap/Form';

// Store
import { StoreContext } from '../../store/reducer';
import { setSearchSimilar, uploadImage, setDownloadDXFTP, setDownload3D, setDownloadGlb } from '../../store/action';

const DeepSearch = () => {

  const [failedImages, setFailedImages] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState({ fileType: '', styleNo: '' });
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [emailOpen, setEmailOpen] = useState(false);
  const [targetResult, setTargetResult] = useState({});
  const [department, setDepartment] = useState('PD&D');
  const [email, setEmail] = useState(
    `Dear All,
    Please find below information.
    Style NO. :
    Item :
    Gender :
    IE / YY : link
    Image : link`);

  const [finalResults, setFinalResults] = useState([]);

  const {
    state: {
      isShowDetail,
      searchResults,
      detailSelections: { unit },
      requestdata: { loading },
    },
    dispatch,
  } = useContext(StoreContext)

  const localFilterResults = JSON.parse(localStorage.getItem('localFilterResults'));
  useEffect(() => {
    if (loading === false) {
      if (searchResults.length === 0) {
        if (localFilterResults) {
          setFinalResults(JSON.parse(JSON.stringify(localFilterResults)));
        }
      } else {
        setFinalResults(JSON.parse(JSON.stringify(searchResults)));
      }
    }
  }, [loading, searchResults])

  useEffect(() => {
    if (popupOpen === false) {
      const timer = setTimeout(() => {
        setEmailOpen(false);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [popupOpen])

  useEffect(() => {
    setEmail(
      `Dear All,
      Please find below information.
      Style NO. : ${targetResult.style}
      Item : ${targetResult.item}
      Gender : ${targetResult.category}
      IE / YY : link
      Image : link`)
  }, [targetResult])

  const handlePicClick = (target) => {
    let targetObj = JSON.parse(JSON.stringify(target));
    targetObj.src_f_max = target.src.replace(/_t/, '');
    if (target['src_b']) {
      targetObj.src_b_max = target.src_b.replace(/_t/, '');
    }
    setTargetResult(targetObj);
    setPopupOpen(true);
  }


  // const handle3DClick = async (target) => {
  //   let src_f = target.mode === '2D' ? target.src : target.src.replace(/_t/, '');
  //   let obj = {
  //     image_f: src_f,
  //     mode: target.mode,
  //   }
  //   if (target.hasOwnProperty('src_b')) {
  //     let src_b = target.src_b.replace(/_t/, '');
  //     obj['image_b'] = src_b;
  //   }
  //   const res = await setDownload3D(dispatch, obj);
  //   saveAs(res, `${target.style}_3D.png`);
  //   setSnackBarText(`The 3D file for ${target.style} has been successfully downloaded.`)
  //   setSnackBarOpen(true);
  // }
  const handle3DClick = async (target) => {
    let obj = {
      src: target.filename,
    }
    const res = await setDownloadGlb(dispatch, obj);
    obj = JSON.parse(JSON.stringify(res));
    console.log(obj.data)
    window.parent.postMessage(obj.data, "*");
  }


  const handleSearchClick = (result) => {
    setPopupOpen(false);
    setSearchSimilar(dispatch, { targetSearch: result });

    let src = result.src.replace(/_t/, '');
    uploadImage(dispatch, { formData: src, searchByImage: true });
  }

  const handleSendClick = () => {
    alert('Send Click');
  }

  const handleSnackBarClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleUnitChange = (num) => {
    let cm = (num * 2.54).toFixed(2);
    return cm;
  }

  const scrollToTop = () => {
    const screenContent = document.getElementById('screenContent');

    if (!screenContent) {
      return;
    }

    let currentScrollY = screenContent.scrollTop;

    const scrollStep = () => {
      if (currentScrollY > 0) {
        currentScrollY = Math.max(0, currentScrollY - 100)
        screenContent.scrollTop = currentScrollY;
        requestAnimationFrame(scrollStep);
      }
    };

    requestAnimationFrame(scrollStep);
  };

  const handleImageError = (index) => {
    setFailedImages([...failedImages, index]);
  };

  const action = (
    <React.Fragment>
      <Button className={styles.snackBarClose} size="small" onClick={handleSnackBarClose}>
        CLOSE
      </Button>
    </React.Fragment>
  );

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portal M: StyleSearch</title>
        <meta name="description" content="Portal M: StyleSearch"/>
      </Helmet>
      <Backdrop
        sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress sx={{ color: "#787ff6" }} size={60} thickness={5} />
      </Backdrop>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        message={snackBarText}
        action={action}
      />
      <div className={styles.screen}>
        <Aside></Aside>
        <Dialog
          open={popupOpen}
          onClose={() => setPopupOpen(false)}
        >
          {emailOpen === false ?
            <DialogContent className={styles.popupContent}>
              <div className={styles.iconContent}>
                <IconButton>
                  <EmailEditOutline
                    className={styles.iconContent_icon}
                    onClick={() => setEmailOpen(true)}
                  ></EmailEditOutline>
                </IconButton>
                <IconButton><CloseCircleOutline
                  className={styles.iconContent_icon}
                  onClick={() => setPopupOpen(false)}
                ></CloseCircleOutline>
                </IconButton>
              </div>
              <div className={styles.title}>{targetResult.style}</div>
              <div className={styles.underLine}></div>
              <div className={styles.imgContent}>
                <img src={targetResult.mode === '3D' ? targetResult.src_f_max : targetResult.src}></img>
                {targetResult.src_b_max ? <img src={targetResult.src_b_max}></img> : <></>}
              </div>
              <div className={styles.funcContent}>
                <div className={styles.funcContent_func} onClick={() => handle3DClick(targetResult)}>
                  <TrayArrowDown className={styles.funcContent_icon}></TrayArrowDown>
                  <div>{targetResult.mode}</div>
                </div>
                <div className={styles.funcContent_func} onClick={() => handleSearchClick(targetResult)}>
                  <ImageSearchOutline className={styles.funcContent_icon}></ImageSearchOutline>
                </div>
              </div>
            </DialogContent>
            :
            <DialogContent className={`${styles.popupContent} ${styles.popupContent_email}`}>
              <div className={styles.iconContent}>
                <IconButton>
                  <ArrowLeftCircleOutline
                    className={styles.iconContent_icon}
                    onClick={() => setEmailOpen(false)}
                  ></ArrowLeftCircleOutline>
                </IconButton>
                <IconButton>
                  <CloseCircleOutline
                    className={styles.iconContent_icon}
                    onClick={() => setPopupOpen(false)}
                  ></CloseCircleOutline>
                </IconButton>
              </div>
              <div className={styles.titleContent}>
                <GamepadCircle className={styles.icon}></GamepadCircle>
                <div className={styles.title}>Send Email</div>
              </div>
              <div className={styles.mainContent}>
                <div className={styles.selectContent}>
                  <div className={styles.selectContent_title}>Style NO.：</div>
                  <div className={styles.value}>{targetResult.style}</div>
                </div>
                <div className={styles.selectContent}>
                  <div className={styles.selectContent_title}>Department：</div>
                  <Form.Select
                    className={styles.formSelect}
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                  >
                    <option value='V7'>V7</option>
                    <option value='PD&D'>PD&D</option>
                  </Form.Select>
                </div>
                <div className={`${styles.selectContent} ${styles.selectContent_email}`}>
                  <div className={styles.selectContent_title}>Edit Email：</div>
                  <div className={styles.emailContent}>
                    <textarea
                      className={styles.emailContent_detail}
                      value={email}
                    >
                    </textarea>
                    <div className={styles.imgContent}>
                      <img src={targetResult.src}></img>
                    </div>
                  </div>
                </div>
              </div>
              <button className={styles.button} onClick={() => handleSendClick()}>Send</button>
            </DialogContent>
          }
        </Dialog>
        <Dialog
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
        >
          <Alert
            fileType={alertText.fileType}
            styleNo={alertText.styleNo}
            handleClose={() => setAlertOpen(false)}
          >
          </Alert>
        </Dialog>
        <div className={styles.screenContent} id='screenContent'>
          <Fab
            className={styles.upFab}
            style={{ position: 'fixed', right: '28px', bottom: '60px' }}
            onClick={scrollToTop}>
            <ChevronUp className={styles.upIcon} />
          </Fab>
          <div className={styles.resultTitle}>
            <GamepadCircle className={styles.icon}></GamepadCircle>
            <div className={styles.resultTitle_title}>Search Results</div>
          </div>
          {finalResults.length === 0 ? <></> : (
            <>{
              isShowDetail ?
                <div className={` ${styles.resultContent} ${styles.resultContent_show}`}>
                  {finalResults.map((result, index) => (
                    <div className={styles.parentContainer}>
                      <div className={styles.mainContent}>
                        <div
                          className={styles.imgContent}
                        >
                          {failedImages.includes(index) ? (
                            <div className={styles.errorImageContent}>
                              <FileDocumentRemoveOutline
                                className={styles.errorIcon}
                              ></FileDocumentRemoveOutline>
                              <div className={styles.errorImageText}>IMAGE NOT</div>
                              <div className={styles.errorImageText}>AVAILABLE</div>
                            </div>
                          ) : (
                            <img
                              src={result.src}
                              className={styles.imgContent_img}
                              onError={() => handleImageError(index)}
                            />
                          )}
                        </div>
                        <div className={styles.detailContent}>
                          <div className={styles.detailContent_title}>Critical Measurements</div>
                          <div className={styles.ul}>
                            {result.hasOwnProperty('across') && result.across !== 0 ?
                              <li>Shoulder : {unit === 'inch' ? result.across : handleUnitChange(result.across)}</li> : null
                            }
                            {result.hasOwnProperty('chest') && result.chest !== 0 ?
                              <li>Chest : {unit === 'inch' ? result.chest : handleUnitChange(result.chest)}</li> : null
                            }
                            {result.hasOwnProperty('sweep') && result.sweep !== 0 ?
                              <li>Sweep : {unit === 'inch' ? result.sweep : handleUnitChange(result.sweep)}</li> : null
                            }
                            {result.hasOwnProperty('body') && result.body !== 0 ?
                              <li>Body L. : {unit === 'inch' ? result.body : handleUnitChange(result.body)}</li> : null
                            }
                            {result.hasOwnProperty('sleeve') && result.sleeve !== 0 ?
                              <li>SLV L. : {unit === 'inch' ? result.sleeve : handleUnitChange(result.sleeve)}</li> : null
                            }
                            {result.hasOwnProperty('waist') && result.waist !== 0 ?
                              <li>Waist : {unit === 'inch' ? result.waist : handleUnitChange(result.waist)}</li> : null
                            }
                            {result.hasOwnProperty('front') && result.front !== 0 ?
                              <li>F Rise : {unit === 'inch' ? result.front : handleUnitChange(result.front)}</li> : null
                            }
                            {result.hasOwnProperty('back') && result.back !== 0 ?
                              <li>B Rise : {unit === 'inch' ? result.back : handleUnitChange(result.back)}</li> : null
                            }
                            {result.hasOwnProperty('length') && result.length !== 0 ?
                              <li>Length : {unit === 'inch' ? result.length : handleUnitChange(result.length)}</li> : null
                            }
                            {result.hasOwnProperty('inseam') && result.inseam !== 0 ?
                              <li>Inseam : {unit === 'inch' ? result.inseam : handleUnitChange(result.inseam)}</li> : null
                            }
                            {result.hasOwnProperty('leg') && result.leg !== 0 ?
                              <li>Leg W. : {unit === 'inch' ? result.leg : handleUnitChange(result.leg)}</li> : null
                            }
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.itemContent} ${styles.itemContent_show}`}>{result.item}</div>
                      <div className={`${styles.hoverContent} ${styles.hoverContent_show}`}>
                        <div className={styles.clickContent} onClick={() => handlePicClick(result)}>
                          <div className={styles.clickContent_title}>{result.style}</div>
                          <div className={styles.clickContent_underLine}></div>
                        </div>
                        <div className={styles.funcContent}>
                          <div className={styles.funcContent_func} onClick={() => handle3DClick(result)}>
                            <TrayArrowDown className={styles.funcContent_icon}></TrayArrowDown>
                            <div>{result.mode}</div>
                          </div>
                          <div className={styles.funcContent_func} onClick={() => handleSearchClick(result)}>
                            <ImageSearchOutline className={styles.funcContent_icon}></ImageSearchOutline>
                          </div>
                        </div>
                      </div>
                      <div className={styles.mode}>
                        <Label className={result.mode === '3D' ? styles.mode_icon : styles.mode_icon_2D}></Label>
                        <div className={styles.mode_title}>{result.mode}</div>
                      </div>
                    </div>
                  ))}
                </div>
                :
                <div className={styles.resultContent}>
                  {finalResults.map((result, index) => (
                    <div className={styles.parentContainer}>
                      <div
                        className={styles.imgContent}
                      >
                        {/* {failedImages.includes(index) ? (
                          <div className={styles.errorImageContent}>
                            <FileDocumentRemoveOutline
                              className={styles.errorIcon}
                            ></FileDocumentRemoveOutline>
                            <div className={styles.errorImageText}>IMAGE NOT AVAILABLE</div>
                          </div> 
                        ) : ( */}
                          <img
                            src={'https://stylesearch.portalm.io/static/image/product/'+result.filename}
                            className={styles.imgContent_img}
                            onError={() => handleImageError(index)}
                          />
                        {/* )} */}
                      </div>
                      <div className={styles.itemContent}>{result.item}</div>
                      <div className={styles.hoverContent}>
                        <div className={styles.clickContent} onClick={() => handlePicClick(result)}>
                          <div className={styles.clickContent_title}>{result.style}</div>
                        </div>
                        <div className={styles.funcContent}>
                          <div className={styles.funcContent_func} onClick={() => handle3DClick(result)}>
                            <TrayArrowDown className={styles.funcContent_icon}></TrayArrowDown>
                            <div>{result.mode}</div>
                          </div>
                          <div className={styles.funcContent_func} onClick={() => handleSearchClick(result)}>
                            <ImageSearchOutline className={styles.funcContent_icon}></ImageSearchOutline>
                          </div>
                        </div>
                      </div>
                      <div className={styles.mode}>
                        <Label className={result.mode === '3D' ? styles.mode_icon : styles.mode_icon_2D}></Label>
                        <div className={styles.mode_title}>{result.mode}</div>
                      </div>
                    </div>
                  ))}
                </div>
            }</>
          )}

        </div>
      </div>
    </Fragment >
  );
};

export default DeepSearch;
import { createContext, useReducer } from "react";
import PropTypes from "prop-types";

import {
    SET_HOMEPAGE_RESET,
    SET_SHOW_DETAIL,
    SET_SEARCH_SIMILAR,
    SET_UPLOAD_RESULT,
    SET_DETAIL_UNIT,

    // 取得資料狀態
    BEGIN_DATA_REQUEST,
    SUCCESS_DATA_REQUEST,
    FAIL_DATA_REQUEST,
} from "./actionTypes";

export const StoreContext = createContext();

const initialState = {
    isShowDetail: false,
    searchSimilar: {
        isSearchSimilar: false,
        targetSearch: [],
    },

    searchResults: [],
    selections: {},

    detailSelections: {
        unit: 'inch',
    },

    // 取得資料狀態
    requestdata: {
        loading: false,
        error: null,
    },
};

function reducer(state, action) {
    switch (action.type) {
        case SET_HOMEPAGE_RESET: {
            return {
                ...state,
                isShowDetail: false,
                searchSimilar: {
                    ...state.searchSimilar,
                    isSearchSimilar: false,
                    targetSearch: [],
                },
                searchResults: [],
            }
        }
        case SET_SHOW_DETAIL:
            return {
                ...state,
                isShowDetail: action.payload,
            }
        case SET_SEARCH_SIMILAR:
            return {
                ...state,
                searchSimilar: {
                    ...state.searchSimilar,
                    isSearchSimilar: true,
                    targetSearch: action.payload,
                }
            }
        case SET_UPLOAD_RESULT:
            return {
                ...state,
                searchResults: action.payload.searchResults,
                selections: action.payload.selections,
            }
        case SET_DETAIL_UNIT:
            return {
                ...state,
                detailSelections: {
                    ...state.detailSelections,
                    unit: action.payload,
                }
            };

        //取得資料狀態
        case BEGIN_DATA_REQUEST:
            return {
                ...state,
                requestdata: { ...state.requestdata, loading: true },
            };
        case SUCCESS_DATA_REQUEST:
            return {
                ...state,
                requestdata: { ...state.requestdata, loading: false },
            };
        case FAIL_DATA_REQUEST:
            return {
                ...state,
                requestdata: {
                    ...state.requestdata,
                    loading: false,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        <StoreContext.Provider value={value}>
            {props.children}
        </StoreContext.Provider>
    );
}

StoreProvider.propTypes = {
    children: PropTypes.object,
};
import React from 'react';
import styles from './styles.module.scss';

// ** Mui-Material Imports
// import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';

// ** Bootstrap Imports
import Form from 'react-bootstrap/Form';


const FormSelect = (prop) => {

  const {
    value,
    onChange,
    selectionsMap = [],
  } = prop;


  return (

    <Form.Select
      className={styles.select}
      value={value}
      onChange={onChange}
    >
      {selectionsMap.length !== 0 && selectionsMap.map((select) => (
          <option key={select} value={select}>{select}</option>
        ))}
    </Form.Select>

  );
};

export default FormSelect;
import React, { } from 'react';
import styles from './styles.module.scss';

// ** Mui-Material Imports
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

// ** Icon Imports
import { PlusBox, MinusBox } from 'mdi-material-ui';

const FormNum = (prop) => {

  const {
    title,
    value,
    top,
    max,
    min,
    onChange,
  } = prop;

  const handleIncrement = () => {
    let newValue;
    if (value === '') {
      newValue = min;
    } else {
      if (parseInt(value, 10) <= max - 1) {
        newValue = parseInt(value, 10) + 1;
      } else {
        newValue = parseInt(value, 10);
      }
    }
    onChange(title, newValue.toString(), top); // 轉換為字串
  };

  const handleDecrement = () => {
    let newValue;
    if (value === '') {
      newValue = min;
    } else {
      if (value >= min + 1) {
        newValue = value - 1;
      } else {
        newValue = value;
      }
    }
    onChange(title, newValue.toString(), top); // 轉換為字串
  };

  return (
    <div className={styles.formNumContent}>
      <TextField
        label={title}
        type="number"
        value={value}
        // color={'#4E3314'}
        className={styles.formNum}
        onChange={(event) => onChange(title, event.target.value, top)}
        InputProps={{
          // inputMode: 'numeric', pattern: '[0-9]*',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleIncrement} edge="end">
                <PlusBox className={styles.icon}></PlusBox>
              </IconButton>
              <IconButton onClick={handleDecrement} edge="end">
                <MinusBox className={styles.icon}></MinusBox>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default FormNum;